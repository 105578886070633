import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from '../components/Services/services.js'

const ServicesPage = () => (
    <Layout>
        <Seo title="Services" description="Pediatrics providing a variety of services to Glendale, Torrance, Pasadena, and West Hills, CA. Contact us at 818-244-9595 or visit us at 1530 E Chevy Chase Dr, Ste 202, Glendale, CA 91206" />
        <Services />
    </Layout>
)

export default ServicesPage
