import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Row, Container, Card } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"

import Title from "../title"

import * as styles from "../../styles/components/Services/services.module.scss"

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulServices(sort: { fields: updatedAt }) {
        edges {
          node {
            title
            slug
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const services = data.allContentfulServices.edges

  return (
    <>
      <Title title="Services" subtitle="What We Offer" />
      <Container className="my-5">
        <Row xs={1} sm={2} md={3} className="g-3 text-center">
          {services.map(({ node }) => (
            <Col className={styles.item}>
              <Link to={`/service/${node.slug}`}>
                <Card style={{ border: "none" }}>
                  <div className={styles.img}>
                    <Card.Img src={node.image.file.url} alt={node.title} />
                  </div>
                  <Card.Body>
                    <h2 className="mt-3 ">
                      <strong>{node.title}</strong>
                    </h2>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}
